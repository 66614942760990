<template>
    <b-card>
        <b-row>
            <b-col cols="9">
                <b-button class="m-1 " v-ripple.400="'#1396EB'" variant="primary" v-b-modal.modal-primaryadd>
                    Добавление
                </b-button>
            </b-col>
            <b-col cols="2">
                <b-form-select class="m-1" v-model="defaultLang" :options="langs" text-field="name" value-field="code"
                    @change="getSlide" />
            </b-col>
        </b-row>
        <b-modal id="modal-primaryadd" @ok="add" @hidden="resetModal" ok-only ok-title="Добавить"
            modal-class="modal-primary" centered title="Добавление">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="slide.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Информация</label>
                        <b-form-textarea v-model="slide.descriptions" id="textarea-default"
                            placeholder="Добавьте информацию" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="slide.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Подзаголовок" label-for="subTitle">
                        <b-form-input v-model="slide.subtitle" id="subTitle" placeholder="Подзаголовок" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание:</label>
                        <b-form-textarea v-model="slide.content" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="selected" class="text-nowrap">Для главной страницы?</label>
                        <b-form-select v-model="slide.selected" :options="options" />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group>
                        <label for="selected" class="text-nowrap">Язык</label>
                    <b-form-select v-model="slide.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
                
            </b-row>
        </b-modal>
        <b-table :fields="tableColumns" responsive="sm" :items="slider">
            <template #cell(actions)="data">
                <div class="text-nowrap">
                    <b-button class="btn-icon mr-1" variant="gradient-primary" id="gradient-primaryy"
                        @click="edit(data.item)" v-b-modal.modal-primaryedit>
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button class="btn-icon" variant="gradient-danger" @click="confirmDelete(data.item)">
                        <feather-icon icon="TrashIcon" />
                    </b-button>
                </div>
            </template>
            <template #cell(img)="data">
                <b-avatar v-if="data.item.img" class="mr-1" :src="data.item.img" />

            </template>
            <template #cell(is_for_home)="data">
                <div class="text-nowrap">
                    {{ data.item.is_for_home == 1 ? 'Для главной страницы' : data.item.is_for_home == 0 ? 'Для страницы о нас' : ''}}
                </div>
            </template>
        </b-table>
        <b-modal @ok="handleEdit" @hidden="resetModal" id="modal-primaryedit" ok-only ok-title="Редактировать"
            modal-class="modal-primary" centered title="Редактирование">
            <b-row>
                <b-col>
                    <b-form-group>
                        <label for="input-file" class="text-nowrap">Изображение:</label>
                        <b-form-file v-model="slide.file" id="input-file" placeholder="Добавьте изображение..."
                            drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Информация</label>
                        <b-form-textarea v-model="slide.descriptions" id="textarea-default"
                            placeholder="Добавьте информацию" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Название" label-for="Title">
                        <b-form-input v-model="slide.title" id="Title" placeholder="Название" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Подзаголовок" label-for="Title">
                        <b-form-input v-model="slide.subtitle" id="Title" placeholder="Подзаголовок" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="textarea-default" class="text-nowrap">Описание:</label>
                        <b-form-textarea v-model="slide.content" id="textarea-default"
                            placeholder="Добавьте описание" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="selected" class="text-nowrap">Для главной страницы?</label>
                        <b-form-select v-model="slide.selected" :options="options" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group>
                        <label for="selected" class="text-nowrap">Язык</label>
                    <b-form-select v-model="slide.lang" :options="langs" text-field="name" value-field="id" />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </b-card>
</template>

<script>
import { BAvatar, BFormSelect, BFormFile, BFormTextarea, BFormInput, BRow, BCol, BFormGroup, BModal, VBModal, BButton, BCard, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { $themeConfig } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
        BAvatar,
        axios,
        BFormSelect,
        ToastificationContent,
        BFormFile,
        BFormTextarea,
        BFormInput,
        BRow,
        BCol,
        BFormGroup,
        BModal,
        VBModal,
        BCard,
        BButton,
        BTable,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {


        return {

            // IMG_URL: $themeConfig.app.IMG_URL,
            defaultLang: 'ru',
            langs: [],
            slider: [],
            slide: {
                descriptions: '',
                file: [],
                subtitle: '',
                file: '',
                content: '',
                link: '',
                is_for_home: '',
                lang: ''
            },
            options: [
                { value: null, text: 'Выберите одну' },
                { value: '1', text: 'Да' },
                { value: '0', text: 'Нет' },

            ],
            tableColumns: [{
                key: 'id',
                label: '#',
                sortable: true,
            },
            {
                key: 'title',
                label: 'Название',
                sortable: true,
            },
            {
                key: 'img',
                label: 'Изображение',
                sortable: true,
            },
            {
                key: 'subtitle',
                label: 'Подзаголовок',
                sortable: true,
            },
            {
                key: 'is_for_home',
                label: 'Тип',
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Действия',
            }]
        }
    },
    mounted() {
        this.getSlide()
        this.getLangs()
    },
    methods: {
        getLangs() {
            axios.get(`${$themeConfig.app.API}langs`)
                .then(res => {
                    this.langs = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },

        getSlide() {
            const getSlide = {
                method: 'get',
                url: `${$themeConfig.app.API}slider`,
                headers: {
                    'Accept-Language': this.defaultLang
                }
            };
            axios(getSlide)
                .then(res => {
                    this.slider = res.data.data
                })
                .catch(er => {
                    console.log(er)
                })
        },
        async add() {
            const myFormData = new FormData()
            myFormData.append('title', this.slide.title)
            myFormData.append('img', this.slide.file)
            myFormData.append('info', this.slide.content)
            myFormData.append('description', this.slide.descriptions)
            myFormData.append('lang_id', this.slide.lang)
            myFormData.append('subtitle', this.slide.subtitle)
            myFormData.append('is_for_home', this.slide.selected)
            await axios({
                method: 'post',
                url: `${$themeConfig.app.API}slider`,
                data: myFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(() => {
                    this.addStatus = true
                    this.getSlide()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Успешно',
                            text: 'Запись успешно добавлен',
                            icon: 'CheckSquareIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch(e => {
                    this.addStatus = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Ошибка',
                            text: e.message,
                            icon: 'CheckSquareIcon',
                            variant: 'danger',
                        },
                    })
                })
        },
        resetModal() {
            this.slide.title = ''
            this.slide.id = ''
            this.slide.lang = ''
            this.slide.file = ''
            this.slide.content = ''
            this.slide.subtitle = ''
            this.slide.selected = ''
        },
        async handleEdit() {
            const myFormData = new FormData()
            myFormData.append('title', this.slide.title)
            myFormData.append('img', this.slide.file)
            myFormData.append('info', this.slide.content)
            myFormData.append('lang_id', this.slide.lang)
            myFormData.append('subtitle', this.slide.subtitle)
            myFormData.append('is_for_home', this.slide.selected)
            myFormData.append('_method', 'PUT')
            if (this.slide.id !== '') {
                await axios({
                    method: 'post',
                    url: `${$themeConfig.app.API}slider/${this.slide.id}`,
                    data: myFormData,
                    config: {},
                })
                    // (`https://back.khojaobigarm.tj/api/news/${this.news.id}`, myFormData)
                    .then(() => {
                        this.getSlide()
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Успешно',
                                text: 'Данные были изменены',
                                icon: 'CheckSquareIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(e => {
                        console.log(e)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ошибка',
                                text: e.message,
                                icon: 'CheckSquareIcon',
                                variant: 'danger',
                            },
                        })
                    })
            }
        },
        edit(data) {
            this.slide.title = data.title
            this.slide.id = data.id
            this.slide.lang = data.lang.id
            this.slide.file = data.img
            this.slide.content = data.info
            this.slide.descriptions = data.description
            this.slide.subtitle = data.subtitle
            this.slide.selected = data.is_for_home
        },
        confirmDelete(data) {
            this.$bvModal
                .msgBoxConfirm('После удаления данные восстановить нельзя будет!', {
                    title: 'Вы уверены что хотите удалить?',
                    size: 'sm',
                    okVariant: 'primary',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отменить',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if (value) {
                        axios.delete(`${$themeConfig.app.API}slider/${data.id}`)
                            .then(() => {
                                this.getSlide()
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Успешно',
                                        text: 'Данные были удалены',
                                        icon: 'CheckSquareIcon',
                                        variant: 'success',
                                    },
                                })
                            })
                            .catch(e => {
                                console.log(e)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Ошибка',
                                        text: e.message,
                                        icon: 'CheckSquareIcon',
                                        variant: 'dander',
                                    },
                                })
                            })
                    }
                })
        },
    }
}
</script>